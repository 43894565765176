@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 207.86 89.74 30.59;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}
.body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
input{
  padding: 10px;
}
td{
  min-width:150px! important;
}
th{
  min-width:150px !important;
}
input,
select,
option {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="file"] {
  padding:0px !important;
}
.btn-loading {
  cursor: not-allowed;
  background-color: #6c757d;
  border-color: #6c757d;
  pointer-events: none;
}

.loader {
  border: 5px solid #f3f3f3;
  /* Light gray border */
  border-top: 5px solid #3498db;
  /* Blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  /* Rotate the spinner */
  margin: 0 auto;
  /* Center the spinner horizontally */
  margin-top: 100px;
  /* Add some vertical margin */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control {
  width: 100%;
  padding: 8px;
  margin: 6px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-label {
  font-weight: bold;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.error-content {
  text-align: center;
}

.error-heading {
  font-size: 36px;
  margin: 0;
}

.error-para {
  font-size: 18px;
  margin: 20px 0;
}

.btn-back-to-home {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s;
}

.btn-back-to-home:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 12px;
}

.error-border {
  color: red;
}

/* Add or modify your existing CSS styles for the table */
.table-container {
  overflow-x: auto;
  margin-top: 30px;
  background-color: white;
  border-radius: 15px;
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

th,
td {
  text-align: left;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

thead {
  font-weight: 800;
  margin-bottom: 2px;
}

/* client.scss */
/* Add pagination styles as needed */
.pagination-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.pagination {
  list-style: none;
  display: flex;
  gap: 10px;
}

.pagination-button {
  padding: 10px 15px;
  cursor: pointer;
  color: #085394;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button--disabled {
  pointer-events: none;
  color: gray;
  background-color: transparent;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: space-between;
  gap: 1rem;
}

.form-row label {
  margin-right: 10px;
  font-weight: bold;
  font-size: 13px;
}

.form-row input {
  flex: 1;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-row select {
  height: 40px;

  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.form-group {
  margin-bottom: 10px;
  width: 35%;
  flex: 1;
}

.row {
  display: flex;
  justify-content: space-between;
}

.submit {
  background-color: #007bff;
  /* Blue button background color */
  color: #fff;
  /* White button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.del {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  display: none;
}

@media (max-width: 1120px) {
  .toolbaar {
    display: flex;
    width: 100%;
    align-items: inherit;
    justify-content: flex-start;
    flex-direction: column;
  }

  .toolbaar .search input[type="search"] {
    margin-bottom: 10px;
  }

  button.SaveTask {
    margin-right: 25% !important;
  }
}

@media (max-width: 768px) {
  .form-card {
    width: 100% !important;
  }
}

@media (max-width: 512px) {
  .toolbaar .search input[type="search"] {
    width: 60%;
  }
}

/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border: 2px solid #508ff4;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #508ff4;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}
td{
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: no-wrap;
  overflow: hidden;
}
.button-17:hover {
  background: #508ff4;
  color: #fff;
}
.react-datepicker-popper{
z-index:13
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.todo-list {
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  /* Adjust the margin as needed */
  height: fit-content;
}

.todo-list h2 {
  font-size: 16px;
  margin-bottom: 10px;
}

.scrollable-list {
  max-height: 300px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.todo-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todo-title {
  font-size: 14px;
  font-weight: bold;
}

.todo-dates {
  font-size: 12px;
  color: #777;
}

.todo-start,
.todo-end {
  margin-left: 10px;
}

.todo-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.todo-list {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.compleated_button {
  width: 5px;
  height: 5px;
  color: #fff;
  /* White button text color */
  border: none;
  border-radius: 50%;
  border: 1px solid gray;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.leader {
  display: flex;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  margin-bottom: 12px;
}

.edit-icon {
  font-size: 12px;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

thead {
  background-color: #f0f0f0;
}

thead {
  font-weight: 800;
  margin-bottom: 2px;
}
tr {
  border-bottom-width: 1px;
  border: 1px solid hsl(214.3 31.8% 91.4%);
}
td {
  text-align: center !important;
  vertical-align: middle;
}
* {
  border-color: hsl(214.3 31.8% 91.4%);
}
ul li {
  list-style: none;
}
.layout_seyction{
  width: 100%;
    min-height: calc(100% - 200px);
    height: calc(100% - 100px);
    max-height: calc(100% - 60px);
    overflow: auto;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

th {
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  background-color: hsl(210deg 40% 96.1% / 50%);
  padding: 12px;
  border-right: none;
  /* background-color: lightgrey; */
  /* Add box-shadow to create a persistent bottom border */
  /* box-shadow: 0 2px 0 0 lightgrey; */
  /* box-shadow: 0 0 2px 0 lightgrey; */
}
