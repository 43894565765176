.user-profile-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  width: 150px;
  height: 150px;
  /* border-radius: 50%; */
  margin-bottom: 10px;
}

.user-details {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-inline: 2rem;
}

.username {
  font-size: 1.5em;
  font-weight: bold;
}

.email {
  color: #555;
}

.user-details-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user-detail {
  width: 300px;
  margin-bottom: 20px;
}

.custom-input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Add any additional styling as needed */
