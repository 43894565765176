/* src/Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #0073e6, #00bfff);
  width: 100%;
}

.login-bg {
  background: white;
  padding: 30px;
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.login-top {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  color: antiquewhite;
  background-color: #0054a6;
  display: flex;
  gap: 20px;
  padding: 6px 0;
  width: 80%;
  border-radius: 10px 10px 0 0;
}

.login-form {
  width: 100%;
  margin: 0 auto;
}

.input-container {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input,
select,
textarea {
  width: 100%;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.form-container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 5px;
  /* width: 100%; */
  margin-top: -40px;
  margin-bottom: 20px;
}

.forgot-password {
  display: flex;
  justify-content: center;
  font-size: 13px;
  gap: 7px;
}

.forgot-password a {
  color: #0073e6;
  text-decoration: none;
}
.error-message {
  color: red;
  font-size: 10px;
  margin-bottom: 10px;
}

.password-toggle-icon {
  margin-right: 10px;
  cursor: pointer;
}

.login-button {
  background-color: #0054a6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.login-button:hover {
  background-color: #0073e6;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

@media (max-width: 768px) {
  .login-bg {
    width: 80%;
  }
}

.forgetpassContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.forgetForm {
  width: 30rem;
  height: auto;
}

.forgetTitle {
  /* Your title styles here */
}

.forgettext {
  /* Your text styles here */
}

.emailForm {
  /* Your form styles here */
}

.mb-3 {
  margin-bottom: 1rem;
}

/* Add more styling as needed for form inputs */
.emailForm label {
  display: block;
  margin-bottom: 0.5rem;
}

.emailForm input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.forgetButton {
  /* Your button styles here */
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.logintext {
  /* Your login text styles here */
  cursor: pointer;
  color: #28a745; /* Green color as an example */
}
