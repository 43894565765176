.loneReq {
  display: flex;
}
.lonenav {
  flex: 6;
  width: 80%;
}
.page-content {
  flex-grow: 1;
  padding: 25px;
  width: 100% !important;
}
.page-title {
  margin-bottom: 15px;
}
.page-title h1 {
  position: relative;
}
.page-title h1 {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 400;
}
.page-title h1::after {
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: -moz-linear-gradient(
    left,
    #1b2135 0,
    #1b2135 8%,
    #d5d8e2 8%,
    #d5d8e2 66%,
    #d5d8e2 66%,
    #d5d8e2 100%
  );
  background: -webkit-linear-gradient(
    left,
    #1b2135 0,
    #1b2135 8%,
    #d5d8e2 8%,
    #d5d8e2 66%,
    #d5d8e2 66%,
    #d5d8e2 100%
  );
  background: linear-gradient(
    to right,
    #1b2135 0,
    #1b2135 8%,
    #d5d8e2 8%,
    #d5d8e2 66%,
    #d5d8e2 66%,
    #d5d8e2 100%
  );
}
section {
  display: block;
}
section .row-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.addBtn {
  position: absolute;
  right: 5%;
}
.searchHead {
  position: fixed;
  margin-top: 5px;
}
.searchBox {
  position: fixed;
  margin-left: 80px !important;
}
.page {
  position: fixed;
  right: 7%;
  margin-top: 20px;
}
.clientCard {
  min-height: 500px;
}

/* client.scss */
/* Add or modify your existing CSS styles for the table */
.table-container {
  overflow-x: auto;
  margin-top: 30px;
  background-color: white;
  border-radius: 15px;
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

th,
td {
  text-align: left;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

thead {
  font-weight: 800;
  margin-bottom: 2px;
}

.pagination {
  list-style: none;
  display: flex;
  gap: 10px;
}

.pagination-button {
  padding: 10px 15px;
  cursor: pointer;
  color: #085394;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button--disabled {
  pointer-events: none;
  color: gray;
  background-color: transparent;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: space-between;
  // align-items: end;
  gap: 1rem;
}

.form-row label {
  // width: 150px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 13px;
}

.form-row input {
  flex: 1;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-row select {
  height: 40px;

  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.form-group {
  margin-bottom: 10px;
  width: 35%;
  flex: 1;
}

// .half-width {
//   flex: 0 0 calc(50% - 15px); /* Two columns with 5px spacing in between */
//   // margin-right: 10px;
// }

.row {
  display: flex;
  justify-content: space-between;
}

.submit {
  background-color: #007bff; /* Blue button background color */
  color: #fff; /* White button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.del {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  display: none;
}
@media (max-width: 1120px) {
  .toolbaar {
    display: flex;
    width: 100%;
    align-items: inherit;
    justify-content: flex-start;
    flex-direction: column;
  }
  .toolbaar .search input[type="search"] {
    margin-bottom: 10px;
  }
  button.SaveTask {
    margin-right: 25% !important;
  }
}

@media (max-width: 768px) {
  .form-card {
    width: 100% !important;
  }
}

@media (max-width: 512px) {
  .toolbaar .search input[type="search"] {
    width: 60%;
  }
}

/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border: 2px solid #508ff4;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #508ff4;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.button-17:hover {
  background: #508ff4;
  color: #fff;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

/* Add this CSS to your project's stylesheet */
.todo-list {
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px; /* Adjust the margin as needed */
}

.todo-list h2 {
  font-size: 16px;
  margin-bottom: 10px;
}

.scrollable-list {
  max-height: 300px; /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.todo-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todo-title {
  font-size: 14px;
  font-weight: bold;
}

.todo-dates {
  font-size: 12px;
  color: #777;
}

.todo-start,
.todo-end {
  margin-left: 10px;
}

.todo-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.todo-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.compleated_button {
  width: 5px;
  height: 5px;
  color: #fff; /* White button text color */
  border: none;
  border-radius: 50%;
  border: 1px solid gray;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.leader {
  display: flex;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  margin-bottom: 12px;
}

.edit-icon {
  font-size: 12px;
}
