.join_meeting_btn{
    width: 10vw;
    height: 5vh;
    background-color: #085394e6;
    color: white;
    border-radius: 5px;
    cursor: pointer;

}
.MuiListItemIcon-root{
    min-width: auto !important;
}
.MuiListItemAvatar-root{
    min-width: auto !important;
}
.MuiListItemText-root{
    display: flex;
    justify-content: center;
    align-items: center;
}