* {
  font-family: "Nunito";
}

.sidebar {
  min-height: 100vh;
  background-color: #085394;
  overflow-y: auto;
  overflow-x: hidden;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: auto;
      width: 180px;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid white;
  }
}

/* Sidebar.css */
.sidebar-container {
  border-top: 3px solid #eaf2ff;
  border-bottom: 3px solid #eaf2ff;
  padding-top: 15px;
}

.sidebar-link {
  display: flex;
  margin-left: 20%;
  width: 80%;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 20px;
  padding: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.sidebar-link.active {
  background-color: #eaf2ff !important;
  color: #629bf5;
  // font-weight: 800;
  padding: 2px 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative; /* Add position relative to enable absolute positioning for pseudo-elements */
}

.sidebar-link.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: -30px;
  height: 30px;
  width: 12px;
  border-radius: 0 0 30px 0;
  box-shadow: 0 15px 0 0 #eaf2ff;
}

.sidebar-link.active::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -30px;
  height: 30px;
  width: 12px;
  border-radius: 0 30px 0 0;
  box-shadow: 0 -15px 0 0 #eaf2ff;
}

.icon-wrapper {
  margin-right: 10px;
}

.lable {
  font-size: 14px;
}

.collapsible-item {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  margin-left: 20%;
  width: 80%;
}

.arrow {
  position: absolute;
  right: 10px;
  font-size: 12px;
  transition: transform 0.3s ease;
}

.collapsible-item.open .arrow {
  transform: rotate(-180deg);
}

.submenu {
  margin-left: 20px;
}

// @media (max-width: 768px) {
//   .sidebar-container {
//     display: none;
//   }
// }

// @media (min-width: 768px) {
//   .mobile-sidebar-container {
//     display: none;
//   }
// }
.sidebar-toggle {
  display: flex;
  gap: 10px;
  margin: 10px 20px;

  .toggle-btn {
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active {
      background-color: #fff;
      color: #1976d2;
    }

    &:hover {
      opacity: 0.8;
    }
  }}