.navbar {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  background-color: #eaf2ff;

  .wrapper {
    width: 100%;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;

      padding: 10px;
      background-color: whitesmoke;
      border-radius: 8px;
      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        cursor: pointer;

        .icon {
          font-size: 20px;
        }
        .active-icon {
          color: #512da8;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .profileImage {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #512da8;
          font-size: 35px;
          color: #fff;
          margin-bottom: -1px;
          text-align: center;
          line-height: 50px;
          margin-left: 5px;
        }

        .counter {
          width: 18px;
          height: 18px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}

.wrapperInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  margin-top: 0px !important;
}
.navbar .wrapper .items {
  position: relative;
  top: 0px !important;
}
.css-14nj4gg-MuiSvgIcon-root,
.navbar .wrapper .search,
.navbar .wrapper .items {
  position: relative;
  top: 0px;
}
.navbar .wrapper .search {
  height: 35px;
  border-radius: 4px;
}
.navbar .wrapper .items .item .profileImage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #53adea;
  font-size: 25px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.userTxt {
  display: flex;
}
.userName {
  font-size: 20px;
  font-weight: 400;
  margin-top: "5px";
}

.item {
  position: relative;
  display: inline-block;
}

.avatar {
  cursor: pointer;
  transition: transform 0.2s;
}

.avatar:hover {
  transform: scale(1.1); /* Add a scaling effect on hover */
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 150px;
  z-index: 1000;
  color: #000;
  font-family: Arial, sans-serif;
}

.links {
  list-style: none;
  padding: 5px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.link {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.3s;
  position: relative;
  margin-top: 5px;
}

.link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #007bff;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
}

.link:hover {
  color: #007bff;
}

.link:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

// .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
//   opacity: 1;
//   transform: none;
//   transition: opacity 314ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 209ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//   top: 20px;
//   left: 948px;
//   height: 200px;
//   transform-origin: 301px 0px;
// }

/* You may also want to style other elements within the sidebar for this full-screen view */

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.modalNotify.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  width: 385px;
}
.modalNotify .mainNoti {
  display: block;
  background: #f8f8f8;
  width: 100%;
  padding: 10px;
}

.modalNotify .mainNoti .title h3 {
  font-size: 18px;
  font-weight: 700;
}
.modalNotify .mainNoti .body span {
  font-size: 14px;
}

.dropdown-menu {
  background-color: white;
  display: flex;
  color: #000000;
  border-radius: 10px;
  padding: 5px;
}

.menubar {
  display: none;
  // background-color: #333;
  z-index: 2000;
}

/* Show the "menubar" when the screen width is below 764 pixels */
@media (max-width: 764px) {
  .menubar {
    display: block;
  }
  .mobileSideBar {
    display: block;
  }
}

.mobileSideBar {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  z-index: 1000;
  display: flex;
  flex: 1;
  // justify-content: center;
  background-color: #eaf2ff;
  padding: 100px 0;
  height: 100vh;
  overflow-y: auto;

  ul {
    display: flex;
    flex-direction: column;

    .sidebarLink {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      
      flex: 1;
      // flex-direction: column;
      justify-items: center;
      text-decoration: wavy;

      .spanfont {
        font-size: 30px;
        font-weight: 400;
        margin-top: "5px";
      }
    }
  }
}
